import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { Textarea } from '@finn/design-system/atoms/textarea';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils/lib/cn';
import { useState } from 'react';

const OTHER_OPTION = 'Sonstiges';
const COMPARISON_OPTIONS = [
  'Marken',
  'Modelle',
  'Preise',
  'Sitzplätze',
  'Verbrauch',
  'Leistung',
  'Reichweite (WLTP)',
  'Multimedia',
  'Reifen',
  'Motoren',
  OTHER_OPTION,
];

export const ComparisonModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose(): void;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [sentFeedback, setSentFeedback] = useState<boolean>(false);

  const selectOption = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const sendFeedback = () => {
    setSentFeedback(true);
    interactionTrackingEvent(TrackingEventName.COMPARISON_MODAL_SUBMITTED, {
      options: selectedOptions,
      description,
    });
  };

  const isMobile = useMediaQuery('xs');

  const [description, setDescription] = useState('');

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <ModalContent variant="small" drawer={isMobile}>
        {!sentFeedback ? (
          <>
            <ModalHeader>
              <ModalTitle>
                Was würdest du gerne miteinander vergleichen?
              </ModalTitle>
            </ModalHeader>
            <div className="body-16-light">Mehrere Auswahl möglich:</div>
            <div className="flex flex-row flex-wrap gap-2">
              {COMPARISON_OPTIONS.map((option) => (
                <Button
                  key={option}
                  variant="outline"
                  className={cn(
                    'border-pearl !font-light focus:bg-white focus:text-black',
                    {
                      'border-black': selectedOptions.includes(option),
                    }
                  )}
                  size="md"
                  onClick={() => selectOption(option)}
                >
                  {option}
                </Button>
              ))}
            </div>
            {selectedOptions.includes(OTHER_OPTION) && (
              <Textarea
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Bitte angeben"
                value={description}
              />
            )}

            <div className="pt-4">
              <Button
                variant="primary"
                className="w-full"
                disabled={selectedOptions.length === 0}
                onClick={sendFeedback}
              >
                Absenden
              </Button>
            </div>
          </>
        ) : (
          <>
            <ModalHeader>
              <ModalTitle>Vielen Dank für dein Feedback!</ModalTitle>
            </ModalHeader>
            <div className="body-16-light">
              Leider ist diese Funktion aktuell noch nicht verfügbar, aber wir
              arbeiten bereits intensiv daran, die FINN-Website noch weiter
              deinen Bedürfnissen anzupassen. Du kannst aber schon jetzt wie
              gewohnt dein neues Traumauto aussuchen und abonnieren.
            </div>
            <div className="pt-4">
              <Button
                variant="primary"
                onClick={() => onClose()}
                className="w-full"
              >
                Weiter
              </Button>
            </div>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
