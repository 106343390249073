import { Calendar } from '@finn/design-system/atoms/calendar';
import { isSameDate } from '@finn/platform-modules';
import {
  addBusinessDays,
  dateDiff,
  isBusinessDay,
  isWeekend,
  useCurrentLocale,
} from '@finn/ui-utils';
import { parseDate } from '@internationalized/date';
import dayjs from 'dayjs';
import { ComponentProps, useCallback, useMemo } from 'react';

import { AvailabilityMap } from '../../../filters-management';

type Props = {
  startDate?: Date | null;
  onChange(nextStartDate: Date): void;
  availability: string[];
  disabled?: boolean;
};

// Map availability dates with startData and endDate
export const getAvailabilityMap = (data: string[]): AvailabilityMap => {
  const groups = [];
  const dateFormat = 'YYYY-MM-DD';
  const businessDays = data.filter((date) => !isWeekend(new Date(date)));

  let prevDate = new Date(data[0]);
  let sameRangeDates = [];
  for (const businessDay of businessDays) {
    const date = new Date(businessDay);
    // To know if it's consecutive days or not
    if (dateDiff(prevDate, date) > 1) {
      groups.push(sameRangeDates);
      sameRangeDates = [];
    }

    sameRangeDates.push(date);
    prevDate = date;
  }

  if (sameRangeDates.length) {
    groups.push(sameRangeDates);
  }

  const dates: AvailabilityMap = {};
  for (const group of groups) {
    for (const date of group) {
      dates[dayjs(date).format(dateFormat)] = {
        startDate: dayjs(group[0]).format(dateFormat),
        endDate: dayjs(group[group.length - 1]).format(dateFormat),
      };
    }
  }

  return dates;
};

export const WeekPickerNext = ({
  availability,
  startDate,
  onChange,
}: Props) => {
  const { locale } = useCurrentLocale();

  const availabilityMap = useMemo<AvailabilityMap>(
    () => getAvailabilityMap(availability),
    [availability]
  );

  const handleWeekChange = (date: dayjs.Dayjs | null) => {
    if (!date?.isValid()) return;
    const dateFormat = 'YYYY-MM-DD';
    const formattedDate = dayjs(date).format(dateFormat);
    const start = availabilityMap[formattedDate]?.startDate;

    onChange(dayjs(start).toDate());
  };

  const isDateDisabled = (date: dayjs.Dayjs | null) => {
    if (!date) return false;
    if (!isBusinessDay(date)) return true;

    const isIncluded = availability.some((avDate) =>
      isSameDate(dayjs(avDate), date, 'day')
    );

    return !isIncluded;
  };

  const handleRangeValueChange: ComponentProps<typeof Calendar>['onChange'] =
    useCallback(
      ({ start }) => {
        handleWeekChange(dayjs(start.toString()));
      },
      [handleWeekChange]
    );

  const isDateInRangeDisabled = useCallback(
    (date: ReturnType<typeof parseDate>) =>
      isDateDisabled(dayjs(date.toString())),
    [isDateDisabled]
  );

  const rangeValue = useMemo(() => {
    if (!startDate) {
      return undefined;
    }

    const formattedDate = dayjs(startDate.toString()).format('YYYY-MM-DD');
    const start = availabilityMap[formattedDate]?.startDate;
    const end = availabilityMap[formattedDate]?.endDate;
    const startValue = dayjs(start);

    const endValue = dayjs(end || addBusinessDays(startValue.toString(), 4));

    return {
      start: parseDate(startValue.format('YYYY-MM-DD')),
      end: parseDate(endValue.format('YYYY-MM-DD')),
    };
  }, [startDate, availabilityMap]);

  return (
    <div className="picker -mr-2">
      <Calendar
        withOneClickRangeSelection
        className="px-2 py-2"
        variant="range"
        locale={locale}
        value={rangeValue}
        isDateUnavailable={isDateInRangeDisabled}
        onChange={handleRangeValueChange}
      />
    </div>
  );
};
