import {
  ProductCard,
  useFetchProduct,
  useGetPreviousCart,
} from '@finn/ua-vehicle';

export const RecentlyViewed = ({
  partnerDiscount,
}: {
  partnerDiscount?: number;
}) => {
  const previousCart = useGetPreviousCart();
  const { data } = useFetchProduct({
    vehicleId: previousCart?.vehicleId,
  });

  if (!data) {
    return null;
  }

  return (
    <div className="border-r-snow border-r pr-2 md:pr-4">
      <ProductCard
        parentTitle="Recently Viewed"
        position={-1}
        lastVisited
        vehicle={data}
        partnerDiscount={partnerDiscount}
      />
    </div>
  );
};
