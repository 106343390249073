import { If } from '@finn/design-system/atoms/If';
import { Spinner } from '@finn/design-system/atoms/spinner';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { cn, useCurrentLocale } from '@finn/ui-utils';
import { ReactNode, useMemo } from 'react';

import { FiltersResponse, FilterValuesObject } from '../core';
import { useGetPreviousCart } from '../pdp';
import {
  CampaignCard,
  CampaignCardProps,
  CampaignCardWithType,
  CombinedItem,
  GenericVehicleDetails,
  isModelCard,
  ItemType,
  ModelCard,
  ModelCardWithType,
  ProductCard,
  useCampaignCards,
} from '../product-cards';
import { RetentionCards } from '../retention';
import { Pagination } from './components/Pagination';
import { ProductListInfiniteScrollTrigger } from './components/ProductListInfiniteScrollTrigger';
import { RecentlyViewed } from './components/RecentlyViewed';
import { ZeroProduct } from './components/ZeroProduct';
import {
  useVehiclesByModel,
  VehicleOrModelDetails,
} from './hooks/useModelCards';

type ProductListingProps = {
  status: 'loading' | 'success' | 'error' | 'empty' | 'validating';
  items: GenericVehicleDetails[];
  campaignData?: CampaignCardProps[];
  partnerDiscount?: number;
  paginationType: 'infinite' | 'pagination';
  page?: number;
  total?: number;
  limit?: number;
  fetchNextPage?: () => void;
  children?: ReactNode;
  filterValues?: FilterValuesObject;
  availableFilters?: FiltersResponse;
  isOutOfStock?: boolean;
  withRetentionCards?: boolean;
  onMessage?: (
    type: 'model_card_selected' | 'reset_filters',
    item?: ModelCardWithType,
    position?: number
  ) => void;
};

export const mergeVehiclesAndCampaignCards = (
  vehicles: VehicleOrModelDetails[],
  campaigns?: CampaignCardProps[]
): CombinedItem[] => {
  const mergedList: CombinedItem[] = vehicles.map((vehicle, index) => ({
    ...vehicle,
    position: index,
  }));

  campaigns?.forEach((campaign) => {
    const position = campaign.metadata.default_position - 1;

    mergedList.splice(position, 0, {
      ...campaign,
      type: ItemType.CAMPAIGN_CARD,
    });
  });

  return mergedList;
};

// Type guards
export const isCampaignCard = (
  item: CombinedItem
): item is CampaignCardWithType => {
  return item.type === ItemType.CAMPAIGN_CARD;
};

export const ProductListing = ({
  status,
  items,
  campaignData,
  partnerDiscount,
  page,
  total,
  limit,
  paginationType,
  filterValues = {},
  availableFilters,
  isOutOfStock,
  // withRetentionCards -> requres nextjs session
  withRetentionCards = true,
  fetchNextPage,
  onMessage,
}: ProductListingProps) => {
  const campaignCards = useCampaignCards(campaignData);
  const { isDE } = useCurrentLocale();
  const { mergedVehicles } = useVehiclesByModel({
    vehicles: items,
    filterValues,
    availableFilters,
    isOutOfStock,
  });
  const isExpModelCards = useIsABVariant(Features.ExpPLPModelCards);
  const hasSelectedModel = !!filterValues?.models?.length;
  const hasDealsFilter = !!filterValues.has_deals;
  const showComparisonInfo =
    isExpModelCards && (hasSelectedModel || hasDealsFilter);

  const { terms } = filterValues;
  const selectedTerm = terms?.[0];

  const isCustomizeExp = useIsABVariant(Features.ExpCustomization);
  const previousCart = useGetPreviousCart();
  const lastVisitedVehicle = previousCart?.vehicleId;
  const hasFilters = Object.keys(filterValues).length > 0;

  const combinedList = useMemo(() => {
    let list = mergeVehiclesAndCampaignCards(mergedVehicles, campaignCards);
    if (isCustomizeExp && !hasFilters && lastVisitedVehicle) {
      list = list.filter(
        (item) =>
          item.type === ItemType.CAMPAIGN_CARD ||
          (item.type === ItemType.VEHICLE && item.id !== lastVisitedVehicle) ||
          (item.type === ItemType.MODEL_CARD &&
            item.brand !== previousCart.brand &&
            item.model !== previousCart.model)
      );
    }

    return list;
  }, [
    mergedVehicles,
    campaignCards,
    isCustomizeExp,
    hasFilters,
    lastVisitedVehicle,
    previousCart,
  ]);

  return (
    <div className="flex flex-wrap gap-6">
      <If condition={status === 'loading'}>
        <div className="w-full">
          <div className="text-center">
            <Spinner className="fill-trustedBlue h-12 w-12" />
          </div>
        </div>
      </If>
      <div className="w-full">
        {items.length ? (
          <div
            className={cn(
              'grid w-full grid-cols-[repeat(2,minmax(120px,1fr))] gap-x-2 gap-y-6 md:grid-cols-[repeat(auto-fill,minmax(280px,1fr))] md:gap-x-4 lg:grid-cols-[repeat(3,minmax(280px,1fr))]',
              {
                'grid-cols-[repeat(1,minmax(120px,1fr))]': showComparisonInfo,
              }
            )}
          >
            {isDE && withRetentionCards && <RetentionCards />}
            {isCustomizeExp && !hasFilters && (
              <RecentlyViewed partnerDiscount={partnerDiscount} />
            )}

            {combinedList.map((item, index) => {
              if (isCampaignCard(item)) {
                return (
                  <div key={`campaign-${item.slug}-${index}`}>
                    <CampaignCard campaignData={item} />
                  </div>
                );
              }

              if (isModelCard(item)) {
                return (
                  <div
                    key={`${item.brand}-${item.model}`}
                    data-testid="model-card"
                  >
                    <ModelCard
                      urlParams={
                        (selectedTerm && `?term=${selectedTerm}`) || ''
                      }
                      onModelCardSelected={() =>
                        onMessage('model_card_selected', item, index + 1)
                      }
                      modelInfo={item}
                      partnerDiscount={partnerDiscount}
                    />
                  </div>
                );
              }

              return (
                <div key={item.id} data-testid="product-card">
                  <ProductCard
                    showComparisonInfo={showComparisonInfo}
                    lastVisited={
                      isCustomizeExp && lastVisitedVehicle === item.id
                    }
                    shouldPreload
                    vehicle={item}
                    position={index + 1}
                    selectedTerm={selectedTerm}
                    partnerDiscount={partnerDiscount}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <If condition={status !== 'loading' && status !== 'validating'}>
            <ZeroProduct
              filterValues={filterValues}
              onResetFilters={() => onMessage('reset_filters')}
            />
          </If>
        )}
      </div>
      <div className="flex basis-full justify-center">
        <If condition={paginationType === 'infinite'}>
          <ProductListInfiniteScrollTrigger
            isLoading={status !== 'loading' && status === 'validating'}
            loadNextPage={fetchNextPage}
          />
        </If>
        <If condition={paginationType === 'pagination' && total > 0}>
          <Pagination
            shallow
            disabled={status === 'loading'}
            page={page ?? 1}
            totalPages={Math.ceil(total / limit)}
          />
        </If>
      </div>
    </div>
  );
};
