import { CarFilled } from '@finn/design-system/icons/car-filled';
import {
  dateDiff,
  Deal,
  toDateInstance,
  toISODate,
  useDeals,
} from '@finn/platform-modules';
import { BASE_SUBSCRIPTION_PATH, SERVER_DATE_FORMAT } from '@finn/ua-constants';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import {
  getCloudinaryImgUrl,
  Locale,
  useCurrentLocale,
  useIntl,
  useSession,
} from '@finn/ui-utils';
import dayjs from 'dayjs';
import deLocale from 'dayjs/locale/de';
import enLocale from 'dayjs/locale/en';
import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { useFilterValues } from '../filters-management';
import { usePlpRetentionStore } from './data/usePlpRetentionStore';
import { useReadyForRetentionDeals } from './hooks/useReadyForRetentionDeals';
import { ProlongationModal } from './ProlongationModal';
import { RetentionModal } from './RetentionModal';

export const RetentionCards = () => {
  const [session] = useSession();
  const i18n = useIntl();
  const { locale } = useCurrentLocale();
  const filters = useFilterValues();
  const hasFilter = Object.keys(filters || {}).length;

  const [selectedSubscription, setSelectedSubscription] = useState<Deal>();
  const { deals: subscriptions } = useDeals();
  const { activeSubscriptions } = useReadyForRetentionDeals();

  const openModal = useOpenModal();
  const setLastOpenedSubscription = usePlpRetentionStore(
    (state) => state.setLastOpenedSubscription
  );
  const [isActiveFilter, setActiveFilter] = useState(false);

  // Make Monday the first day of the week for different locales
  dayjs.locale({
    ...(locale === Locale.GERMAN_GERMANY ? deLocale : enLocale),
    weekStart: 1,
  });

  const hasFutureContract = useMemo(
    () =>
      subscriptions?.find((subscription) => {
        const millis = Number(
          toDateInstance(subscription.handover_appointment_date).valueOf()
        );

        return millis > Date.now();
      }) !== undefined,
    [subscriptions]
  );

  const parseEndDate = (subscription: Deal) => {
    const endDate = dayjs(subscription.end_date);

    return dayjs(endDate, { format: SERVER_DATE_FORMAT });
  };

  useEffect(() => {
    const filterIsSetToEndDate = !!subscriptions?.find((subscription) => {
      const parsedEndDate = parseEndDate(subscription);
      const availableStart = parsedEndDate.startOf('week');

      return (
        filters?.available_from === availableStart.format(SERVER_DATE_FORMAT)
      );
    });
    setActiveFilter(filterIsSetToEndDate);
  }, [subscriptions, filters?.available_from]);

  const getEndInDays = (subscription: Deal) => {
    return dateDiff(
      toISODate(new Date()),
      toISODate(parseEndDate(subscription))
    );
  };

  const openRetentionModal = (subscription: Deal) => (e: MouseEvent) => {
    e.preventDefault();
    setSelectedSubscription(subscription);
    openModal(ModalKey.RETENTION_MODAL);
    setLastOpenedSubscription(subscription);
    interactionTrackingEvent(
      TrackingEventName.RETENTION_CAMPAIGN_CARD_CLICKED,
      {}
    );
  };

  const hideRetentionCards = hasFilter || isActiveFilter || hasFutureContract;
  const activeSubscriptionsCount = activeSubscriptions?.length || 0;
  useEffect(() => {
    if (!hideRetentionCards && activeSubscriptionsCount > 0) {
      interactionTrackingEvent(
        TrackingEventName.RETENTION_CAMPAIGN_CARD_SHOWN,
        {
          totalCards: activeSubscriptionsCount,
        }
      );
    }
  }, [hideRetentionCards, activeSubscriptionsCount]);

  if (!session || hideRetentionCards || !activeSubscriptionsCount) return null;

  return (
    <>
      {activeSubscriptions?.map((subscription) => (
        <div
          key={subscription.id}
          className="bg-snow hover:bg-cotton group relative"
        >
          <a
            className="flex h-full min-h-[168px] flex-col px-2 py-4 sm:min-h-[343px] sm:px-4 sm:py-6"
            href={`/${locale}/${BASE_SUBSCRIPTION_PATH}`}
            onClick={openRetentionModal(subscription)}
          >
            <h5 className="body-14-semibold sm:global-t5-semibold">
              {i18n.formatMessage('plp.retention.cardTitle')}
            </h5>
            {subscription.car.picture ? (
              <img
                className="my-2 mix-blend-multiply transition-transform group-hover:scale-105 sm:my-4"
                src={getCloudinaryImgUrl(subscription.car.picture)}
                alt={`${subscription.car.oem} ${subscription.car.model}`}
              />
            ) : (
              <CarFilled className="mx-8 my-2 h-full w-full max-w-[192px] opacity-50 sm:mx-12 sm:my-4" />
            )}
            <div className="flex flex-col gap-1 sm:gap-2">
              <span className="body-12-semibold text-orange">
                {i18n.formatMessage('plp.retention.endsInDays', {
                  days: getEndInDays(subscription),
                })}
              </span>
              <span className="global-t6-semibold sm:global-t5-semibold">
                {`${subscription.car.oem} ${subscription.car.model}`}
              </span>
              <div className="inline-block">
                <span className="body-12-light sm:body-14-light inline-block">
                  {subscription.car.license_plate}
                </span>
              </div>
            </div>
          </a>
        </div>
      ))}
      <ModalContainer
        modalKey={ModalKey.RETENTION_MODAL}
        ModalComponent={RetentionModal}
        selectedSubscription={selectedSubscription}
        location="PLP"
      />
      <ModalContainer
        modalKey={ModalKey.PROLONGATION_MODAL}
        ModalComponent={ProlongationModal}
        location="Retention Campaign Card PLP"
        subscriptionId={selectedSubscription?.id}
      />
    </>
  );
};
