import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { FilterListDefault } from '@finn/design-system/icons/filter-list-default';
import { SwapHoriz } from '@finn/design-system/icons/swap-horiz';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { useIntl } from '@finn/ui-utils';
import { memo, ReactNode, useCallback, useState } from 'react';

import {
  FiltersState,
  useAvailableFiltersForState,
  useFilterState,
  useFilterValues,
  useInitialFilterValues,
  useMergeFilterValues,
} from '../../../filters-management';
import { ComparisonModal } from '../ComparisonModal';
import { FiltersDrawer } from '../FiltersDrawer';
import { SortBy } from '../individual-filters/SortBy';
import { FiltersGroup } from './FiltersGroup';

export const CombinedFilters = memo(
  ({
    children,
    aboveFiltersChildren,
    sortSecondaryFilters,
  }: {
    children?: ReactNode;
    aboveFiltersChildren?: ReactNode;
    sortSecondaryFilters?: (a: { name: string }, b: { name: string }) => number;
  }) => {
    const i18n = useIntl();

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const mergeFilterValues = useMergeFilterValues();
    const filterValues = useFilterValues();

    const initialValues = useInitialFilterValues(filterValues);

    const toggleDrawer = useCallback(
      (isOpen: boolean) => {
        setIsDrawerOpen(isOpen);
      },
      [setIsDrawerOpen]
    );

    const availableFilters = useAvailableFiltersForState(filterValues);

    const [filterState, setFilterState] = useFilterState(
      availableFilters,
      initialValues
    );
    const availableFiltersFromState = useAvailableFiltersForState(filterState);

    const handleFiltersDesktop = useCallback(
      (selectedFilters: FiltersState) => {
        const updatedFilters = setFilterState(selectedFilters);
        mergeFilterValues(updatedFilters);
      },
      [mergeFilterValues]
    );

    const isMobile = useMediaQuery('md');
    const openModal = useOpenModal();
    const openComparison = () => {
      openModal(ModalKey.COMPARISON_MODAL);
      interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
        modal: 'comparison',
      });
    };
    const isComparisonExp = useIsABVariant(Features.ExpComparisonV2);

    return (
      <>
        <div className="z-sticky sticky top-0 flex gap-2 bg-white pt-4 md:hidden">
          <Button
            className="hover:bg-snow group min-h-[52px] w-1/2 border-black px-4 py-2 hover:text-black"
            variant="outline"
            size={'lg'}
            onClick={() => toggleDrawer(true)}
          >
            <FilterListDefault className="mr-2 max-h-6 max-w-6 group-hover:fill-black group-active:fill-white" />
            {i18n.formatMessage('plp.filterButton')}
          </Button>
          {isComparisonExp && (
            <Button
              className="hover:bg-snow group min-h-[52px] w-1/2 border-black px-3 py-2 hover:text-black"
              variant="outline"
              size={'lg'}
              onClick={openComparison}
            >
              <SwapHoriz className="mr-2 fill-black" />
              Vergleichen
            </Button>
          )}
          <SortBy isMobile={true} />
        </div>
        {isMobile && (
          <FiltersDrawer
            sortSecondaryFilters={sortSecondaryFilters}
            isOpen={isDrawerOpen}
            availableFilters={availableFiltersFromState}
            onToggleDrawer={toggleDrawer}
            aboveFiltersChildren={aboveFiltersChildren}
          >
            {children}
          </FiltersDrawer>
        )}

        <div className="hidden md:block">
          <FiltersGroup
            sortSecondaryFilters={sortSecondaryFilters}
            filtersData={filterState}
            availableFilters={availableFilters}
            onClick={handleFiltersDesktop}
          >
            {children}
          </FiltersGroup>
        </div>

        <ModalContainer
          modalKey={ModalKey.COMPARISON_MODAL}
          ModalComponent={ComparisonModal}
        />
      </>
    );
  }
);

CombinedFilters.displayName = 'CombinedFilters';
