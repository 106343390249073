import { Button } from '@finn/design-system/atoms/button';
import { SwapHoriz } from '@finn/design-system/icons/swap-horiz';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn, isMobileApp, useIntl } from '@finn/ui-utils';
import { useCallback } from 'react';

import { setForBusinessCookie } from '../../../helpers';
import { FilterKey, FilterValuesObject } from '../../core';
import {
  useFilterValues,
  useReplaceFilterValues,
  useResultsCount,
} from '../../filters-management';
import { trackFiltersCleared } from '../../tracking';
import { FilterTagList } from './FilterTagList';
import { SortBy } from './individual-filters/SortBy';

const removeProductGroupFilter = (
  filters: FilterValuesObject
): FilterValuesObject => {
  return Object.fromEntries(
    Object.entries(filters).filter(([key]) => key !== FilterKey.PRODUCT_GROUP)
  );
};

export const CombinedFiltersTags = () => {
  const i18n = useIntl();
  const resultsCount = useResultsCount();
  const filterValues = useFilterValues();
  const replaceFilterValues = useReplaceFilterValues();
  const isApp = isMobileApp();
  const isExpPLPReducedFiltersB = useIsABVariant(Features.ExpPLPReducedFilters);
  const isExpPLPReducedFiltersC = useIsCVariant(Features.ExpPLPReducedFilters);
  const isExpPLPReducedFilters =
    (isExpPLPReducedFiltersB || isExpPLPReducedFiltersC) && !isApp;

  const filteredFilterValues = removeProductGroupFilter(filterValues);
  const appliedFiltersCount = Object.entries(filteredFilterValues).length ?? 0;

  const handleReset = useCallback(() => {
    replaceFilterValues({});
    setForBusinessCookie(false);
    trackFiltersCleared();
  }, [replaceFilterValues]);

  const openModal = useOpenModal();
  const openComparison = () => {
    openModal(ModalKey.COMPARISON_MODAL);
    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      modal: 'comparison',
    });
  };

  const isComparisonExp = useIsABVariant(Features.ExpComparisonV2);

  return (
    <div
      className={cn(
        'z-[1] flex items-center bg-white md:top-0',
        'sticky top-[72px] mb-1',
        'md:min-h-auto h-10 min-h-0 overflow-hidden md:min-h-12 md:overflow-auto'
      )}
    >
      <div className="flex items-center">
        <div className="body-12-light inline-block p-2.5 pl-0 pr-2">
          {`${resultsCount} ${i18n.formatMessage(isExpPLPReducedFilters ? 'experiments.reducedFiltersV2.cars' : 'plp.cars')}`}
        </div>
        {appliedFiltersCount > 0 && (
          <Button
            variant="action"
            size="sm"
            className="mr-4"
            data-testid="reset-filters"
            onClick={handleReset}
          >
            {i18n.formatMessage(
              isExpPLPReducedFilters
                ? 'experiments.reducedFiltersV2.clearFilter'
                : 'plp.clearFilter'
            )}
          </Button>
        )}
      </div>

      <>
        <div className="relative mr-2 min-h-[29px] grow">
          <div
            className={cn(
              'absolute bottom-0 left-0 right-0 top-0 h-full w-full'
            )}
          >
            <FilterTagList />
          </div>
        </div>
      </>

      <div className="hidden flex-row flex-nowrap gap-2 md:flex">
        {isComparisonExp && (
          <Button
            className="hover:bg-snow group h-10 border-black hover:text-black"
            variant="outline"
            size="sm"
            onClick={openComparison}
          >
            <SwapHoriz className="mr-2 !h-6 !w-6 fill-black" />
            Vergleichen
          </Button>
        )}

        <SortBy isMobile={false} />
      </div>
    </div>
  );
};
